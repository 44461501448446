const app = {
  init: () => {
    const _this = app;

    svg4everybody();

    _this.modal();
    _this.toggler(_this);
    _this.gallery();
    _this.yaMap();
  },

  modal() {
    const modal = new Unimodal({
      onOpen: (modal, button) => {
        /* ... do your staff here ... */
      },
      onClose: modal => {
        /* ... do your staff here ... */
      }
    });
  },

  scrollTrigger() {
    let elHeader = document.querySelector('#header');

    zenScroll.setup(null, elHeader.offsetHeight);

    window.addEventListener('scroll', e => {
      if (window.scrollY > 0) {
        if (!elHeader.classList.contains('is-scroll')) {
          elHeader.classList.add('is-scroll');
        }
      } else {
        elHeader.classList.remove('is-scroll');
      }
    });
  },

  toggler(_this) {
    const toggler = new Unitoggle({
      onOpen: tab => {
        if (tab.id == 'menu') {
          if (window.innerWidth < 768) {
            _this.blockScroll(true);
          }
        }
      }, // After open callback
      onClose: tab => {
        _this.blockScroll(false);
      } // After close callback
    });
  },

  blockScroll(status = false) {
    if (!status) {
      document.documentElement.classList.remove('is-no-scroll');
      return;
    }
    document.documentElement.classList.add('is-no-scroll');
  },

  gallery() {
    let elems = document.querySelectorAll('.js-gallery');

    if (!elems.length) return;

    elems.forEach(el => {
      lightGallery(el, {
        selector: '.js-link',
        download: false,
        loop: false,
        // getCaptionFromTitleOrAlt: true
      });
    });
  },

  yaMap() {
    let el = document.querySelector('#map');

    if (!el) return;

    ymaps.ready(() => {
      let coords = JSON.parse(el.dataset.coords);

      let myMap = new ymaps.Map("map", {
        center: coords, // Координаты центра карты
        zoom: 16, // от 0 (весь мир) до 19
        controls: ['typeSelector', 'fullscreenControl', 'zoomControl']
      });

      let myPlacemark = new ymaps.Placemark(coords, {}, {
        iconLayout: 'default#image',
        iconImageHref: el.dataset.mark,
        iconImageSize: [50, 50],
        iconImageOffset: [-25, -50]
      });

      myMap.geoObjects.add(myPlacemark);
    });
  }
};
