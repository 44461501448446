(function () {
  const app = {
    init: () => {
      const _this = app;
  
      svg4everybody();
  
      _this.modal();
      _this.toggler(_this);
      _this.gallery();
      _this.yaMap();
    },
  
    modal() {
      const modal = new Unimodal({
        onOpen: (modal, button) => {
          /* ... do your staff here ... */
        },
        onClose: modal => {
          /* ... do your staff here ... */
        }
      });
    },
  
    scrollTrigger() {
      let elHeader = document.querySelector('#header');
  
      zenScroll.setup(null, elHeader.offsetHeight);
  
      window.addEventListener('scroll', e => {
        if (window.scrollY > 0) {
          if (!elHeader.classList.contains('is-scroll')) {
            elHeader.classList.add('is-scroll');
          }
        } else {
          elHeader.classList.remove('is-scroll');
        }
      });
    },
  
    toggler(_this) {
      const toggler = new Unitoggle({
        onOpen: tab => {
          if (tab.id == 'menu') {
            if (window.innerWidth < 768) {
              _this.blockScroll(true);
            }
          }
        }, // After open callback
        onClose: tab => {
          _this.blockScroll(false);
        } // After close callback
      });
    },
  
    blockScroll(status = false) {
      if (!status) {
        document.documentElement.classList.remove('is-no-scroll');
        return;
      }
      document.documentElement.classList.add('is-no-scroll');
    },
  
    gallery() {
      let elems = document.querySelectorAll('.js-gallery');
  
      if (!elems.length) return;
  
      elems.forEach(el => {
        lightGallery(el, {
          selector: '.js-link',
          download: false,
          loop: false,
          // getCaptionFromTitleOrAlt: true
        });
      });
    },
  
    yaMap() {
      let el = document.querySelector('#map');
  
      if (!el) return;
  
      ymaps.ready(() => {
        let coords = JSON.parse(el.dataset.coords);
  
        let myMap = new ymaps.Map("map", {
          center: coords, // Координаты центра карты
          zoom: 16, // от 0 (весь мир) до 19
          controls: ['typeSelector', 'fullscreenControl', 'zoomControl']
        });
  
        let myPlacemark = new ymaps.Placemark(coords, {}, {
          iconLayout: 'default#image',
          iconImageHref: el.dataset.mark,
          iconImageSize: [50, 50],
          iconImageOffset: [-25, -50]
        });
  
        myMap.geoObjects.add(myPlacemark);
      });
    }
  };
  
  const form = {
    init() {
      const _this = form;
      _this.inputTel();
  
      _this.setup(_this);
      _this.validation();
      _this.sending();
    },
  
    inputTel() {
      const elems = document.querySelectorAll('.js-input-tel');
  
      if (!elems.length) return;
  
      elems.forEach(el => {
        let mask = IMask(el, {
          mask: '{0} (000) 000-00-00',
        });
  
        // el.addEventListener('click', e => {
        //   mask.updateOptions({
        //     lazy: false
        //   });
        // });
      });
    },
  
    setup() {
      this.bouncerSettings = {
        messageAfterField: true,
        messages: {
          missingValue: {
            checkbox: 'Обязательное поле',
            radio: 'Выберите значение',
            select: 'Выберите значение',
            'select-multiple': 'Выберите значение',
            default: 'Обязательное поле'
          },
          patternMismatch: {
            email: 'Не верный формат e-mail',
            default: 'Проверьте формат значения'
          },
          phoneNum: 'Введите верный номер'
        },
        disableSubmit: true
      }
    },
  
    validation() {
      if (typeof Bouncer === 'undefined') return;
  
      window.AppBouncer = new Bouncer('[data-bouncer]', this.bouncerSettings);
  
      document.addEventListener('bouncerRemoveError', e => {
        const field = e.target;
        field.classList.add('is-valid');
      }, false);
  
      document.addEventListener('bouncerShowError', e => {
        const field = e.target;
        field.classList.remove('is-valid');
      }, false);
    },
  
    sending() {
      document.addEventListener('bouncerFormValid', e => {
        const form = e.target;
        // const type = form.dataset.type;
  
        if (form.hasAttribute('method')) {
          form.submit();
          return;
        }
  
        const btn = form.querySelector('[type="submit"]');
  
        const url = form.action + 'assets/php/mail.php';
  
        const fd = new FormData(form);
  
        form.classList.add('is-process');
        btn.setAttribute('disabled', true);
  
        fetch(url, {
          method: 'POST',
          body: fd
        }).then(response => response.json()).then(res => {
          if (res.success) {
            form.classList.add('is-success');
            form.classList.remove('is-process');
            btn.removeAttribute('disabled');
            form.reset();
  
            setTimeout(() => {
              form.classList.remove('is-success');
              if (document.querySelector('.unimodal.is-active')) {
                Unimodal.close(document.querySelector('.unimodal.is-active').id);
              }
            }, 3000);
          }
        });
      }, false);
    }
  };
  
  const slider = {
    init() {
      const _this = slider;
  
      _this.slLetters();
      _this.slLogos();
    },
  
    slLetters() {
      let el = document.querySelector('#sl-letters');
  
      if (!el) return;
  
      const swiper = new Swiper(el, {
        modules: [SwiperNavigation, SwiperPagination],
        slidesPerView: 'auto',
        // centeredSlides: true,
        // loop: true,
        pagination: {
          el: '.ui-swiper-pagination',
          type: 'bullets',
          bulletClass: 'bullet',
          bulletActiveClass: 'is-active',
          clickable: true,
          currentClass: 'is-current',
          lockClass: 'is-lock'
        },
      });
    },
  
    slLogos() {
      let el = document.querySelector('#sl-logos');
  
      if (!el) return;
  
      const swiper = new Swiper(el, {
        modules: [SwiperNavigation, SwiperPagination],
        slidesPerView: 'auto',
        // centeredSlides: true,
        // loop: true,
        pagination: {
          el: '.ui-swiper-pagination',
          type: 'bullets',
          bulletClass: 'bullet',
          bulletActiveClass: 'is-active',
          clickable: true,
          currentClass: 'is-current',
          lockClass: 'is-lock'
        },
      });
    }
  };
  

  document.addEventListener('DOMContentLoaded', () => {
    app.init();
    form.init();
    slider.init();
  });

  window.onload = () => {
    app.scrollTrigger();
  };
}());
