const form = {
  init() {
    const _this = form;
    _this.inputTel();

    _this.setup(_this);
    _this.validation();
    _this.sending();
  },

  inputTel() {
    const elems = document.querySelectorAll('.js-input-tel');

    if (!elems.length) return;

    elems.forEach(el => {
      let mask = IMask(el, {
        mask: '{0} (000) 000-00-00',
      });

      // el.addEventListener('click', e => {
      //   mask.updateOptions({
      //     lazy: false
      //   });
      // });
    });
  },

  setup() {
    this.bouncerSettings = {
      messageAfterField: true,
      messages: {
        missingValue: {
          checkbox: 'Обязательное поле',
          radio: 'Выберите значение',
          select: 'Выберите значение',
          'select-multiple': 'Выберите значение',
          default: 'Обязательное поле'
        },
        patternMismatch: {
          email: 'Не верный формат e-mail',
          default: 'Проверьте формат значения'
        },
        phoneNum: 'Введите верный номер'
      },
      disableSubmit: true
    }
  },

  validation() {
    if (typeof Bouncer === 'undefined') return;

    window.AppBouncer = new Bouncer('[data-bouncer]', this.bouncerSettings);

    document.addEventListener('bouncerRemoveError', e => {
      const field = e.target;
      field.classList.add('is-valid');
    }, false);

    document.addEventListener('bouncerShowError', e => {
      const field = e.target;
      field.classList.remove('is-valid');
    }, false);
  },

  sending() {
    document.addEventListener('bouncerFormValid', e => {
      const form = e.target;
      // const type = form.dataset.type;

      if (form.hasAttribute('method')) {
        form.submit();
        return;
      }

      const btn = form.querySelector('[type="submit"]');

      const url = form.action + 'assets/php/mail.php';

      const fd = new FormData(form);

      form.classList.add('is-process');
      btn.setAttribute('disabled', true);

      fetch(url, {
        method: 'POST',
        body: fd
      }).then(response => response.json()).then(res => {
        if (res.success) {
          form.classList.add('is-success');
          form.classList.remove('is-process');
          btn.removeAttribute('disabled');
          form.reset();

          setTimeout(() => {
            form.classList.remove('is-success');
            if (document.querySelector('.unimodal.is-active')) {
              Unimodal.close(document.querySelector('.unimodal.is-active').id);
            }
          }, 3000);
        }
      });
    }, false);
  }
};
